/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import cookie from 'react-cookie';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader } from 'semantic-ui-react';

import Controller from '../../../components/Controller';

import UserAccountForm from './Form';

import i18n, { __ } from '../../../i18n';
import * as utils from '../../../utils';

@inject('store')
@graphql(gql`mutation updateUser($updateUserMutation:  UpdateUserInput!) {
  updateUser(input: $updateUserMutation) {
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['UserNodeQuery', 'AppQuery']
  }
  })
@observer
export default class UserAccountEdit extends Controller {
  UNSAFE_componentWillMount() {
    this.props.store.currentEntity = null;
  }

  static fetchData({ store }) {
    store.app.title = __('Edit Account');
  }

  request = (values) => {
    const { store } = this.props;
    const props = {};

    if (values.phone && (!values.oldPhone || (values.phone.toString().replace(/[^0-9]/g, '') !== values.oldPhone.toString()))) {
      props.phone = values.phone.toString().replace(/[^0-9]/g, '');
      values.phone = props.phone;
    } else if (!values.phone && values.oldPhone) {
      props.phone = null;
    }

    if (values.email !== values.oldEmail) {
      props.email = values.email;
    }

    if (values.password !== '') {
      props.password = values.password;
    }


    this.setState({ loadingInfo: true });

    return this.props.mutate({
      variables: {
        updateUserMutation: {
          id: store.currentUser.id,
          fullname: values.fullname,
          language: values.language,
          documentNumber: values.documentNumber,
          isNewCode: true,
          ...props
        }
      }
    }).then(() => {
      cookie.save('lang', values.language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
      store.app.locale = values.language;
      i18n.locale = store.app.locale;
      // networkInterface.setUri(`${store.app.url}/graphql?client_id=${client_id}&tz_offset=${currentTimezone}&locale=${store.app.locale}`);
      store.currentUser = Object.assign({}, store.currentUser, values);
      store.snackbar = { active: true, message: __('Account settings updated'), success: true };

      this.setState({ loadingInfo: false });
    }).catch((err) => {
      this.setState({ loadingInfo: false });
      store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false };
    });
  }

  render() {
    let { store } = this.props;

    if (this.state.loadingInfo) return <Loader active />;

    const { email, phone, fullname, language, documentNumber } = store.currentUser;

    return (
      <Controller
        id="UserAccountEdit"
        edit
        title={__('Edit account')}
        form={UserAccountForm}
        values={{
          email: email || '',
          oldEmail: email || '',
          phone: phone || '',
          oldPhone: phone || '',
          fullname,
          documentNumber: documentNumber || '',
          oldDocumentNumber: documentNumber || '',
          language: language && language.length > 2 ? language.substring(0, 2) : language
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
